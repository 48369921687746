<template>
    <v-card>
        <v-card-title class="text-h5">Add Tags</v-card-title>
        <v-card-text>
            <div class="tag-input align-center">
                <v-combobox
                    v-model="model"
                    :filter="filter"
                    :hide-no-data="!searchTags"
                    :items="tags"
                    hide-details
                    :search-input.sync="searchTags"
                    hide-selected
                    multiple
                    small-chips
                    required
                    flat
                    prepend-icon="mdi-tag-multiple"
                >
                    <template v-slot:no-data>
                        <v-list-item>
                            <v-chip color="grey lighten-3" small>
                                {{ searchTags }}
                            </v-chip>
                        </v-list-item>
                    </template>
                    <template
                        v-slot:selection="{
                            attrs,
                            item,
                            parent,
                            selected,
                        }"
                    >
                        <v-chip
                            v-if="item === Object(item)"
                            v-bind="attrs"
                            color="grey lighten-3"
                            :input-value="selected"
                            small
                        >
                            <span class="pr-2">
                                {{ item.text }}
                            </span>
                            <v-icon small @click="parent.selectItem(item)">
                                mdi-close-circle
                            </v-icon>
                        </v-chip>
                    </template>
                    <template v-slot:item="{ index, item }">
                        <v-text-field
                            v-if="editing === item"
                            v-model="editing.text"
                            autofocus
                            flat
                            background-color="transparent"
                            hide-details
                            solo
                            @keyup.enter="edit(index, item)"
                        ></v-text-field>
                        <v-chip v-else color="grey lighten-3" small>
                            {{ item.text }}
                        </v-chip>
                        <v-spacer></v-spacer>
                    </template>
                </v-combobox>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-btn text color="secondary" @click="closeDialog">
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="saveTags" text color="error" :loading="loading">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'

export default {
    name: 'TagsForm',
    props: {
        quoteTags: {
            type: Array,
            required: true,
        },
        setting: Object,
    },
    data: () => ({
        loading: false,
        errorMsg: null,
        tags: [],
        tag: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        searchTags: null,
        model: [],
        editing: null,
    }),
    watch: {
        model(val, prev) {
            if (val.length === prev.length) return
            this.searchTags = null
            this.model = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                        text: v,
                    }
                    this.tags.push(v)
                    this.error = false
                }
                return v
            })
        },
    },
    mounted() {
        this.tags = this.setting.tags
            ? this.setting.tags?.map(t => {
                  return {
                      text: t,
                      value: t,
                  }
              })
            : []
    },
    methods: {
        filter(item, queryText, itemText) {
            if (item.header) return false
            const hasValue = val => (val != null ? val : '')
            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            )
        },
        async saveTags() {
            try {
                this.loading = true
                this.errorMsg = null
                const selectedTags = this.model.map(t => t.text)
                selectedTags.forEach(tag => {
                    if (this.quoteTags?.length > 0) {
                        if (!this.quoteTags.includes(tag)) {
                            this.quoteTags.push(tag)
                        }
                    } else {
                        this.quoteTags.push(tag)
                    }
                })
                this.quoteTags.forEach(tag => {
                    if (this.setting.tags?.length > 0) {
                        if (!this.setting.tags.includes(tag)) {
                            this.setting.tags.push(tag)
                        }
                    } else {
                        this.setting.tags = [tag]
                    }
                })
                await API.updateCompany(this.companyId, {
                    settingId: this.setting.id,
                    tags: this.setting.tags,
                })
                this.$emit('saveTags', [...this.quoteTags])
                this.closeDialog()
            } catch (error) {
                this.errorMsg = error.message
            } finally {
                this.loading = false
            }
        },
        closeDialog() {
            this.$emit('closeTagsDialog')
        },
    },
}
</script>

<style></style>
